/* istanbul ignore file */
import React, { ReactElement } from "react";
import { ListGroup } from "react-bootstrap";
import { DashboardTekort, ControleGewenstInkomenOptions } from "../../../.generated/forms/formstypes";
import { KlantnaamType } from "../../../shared/generic-parts/klantnaam/schema";
import { bedragFormat } from "../../../shared/utils/currency";
import { hasValue } from "../../../shared/utils/helpers";
import { UitgangspuntenType } from "../../infra/dashboard-types";
import "./tekort.scss";

type TekortProps = {
  tekort: DashboardTekort;
  uitgangspunten: UitgangspuntenType;
  beidenTonen?: boolean;
  aanvrager: KlantnaamType;
  partner: KlantnaamType | null;
  header: string; // New prop for the existing header (e.g., 'WW' or 'AO')
};

const TekortItem = ({
  label,
  value,
  perMonth = false
}: {
  label: string;
  value: number | null;
  perMonth?: boolean;
}): ReactElement => (
  <ListGroup.Item className="tekort__item">
    {label}: {hasValue(value) ? `${bedragFormat(perMonth ? value / 12 : value, 0)}${perMonth ? " p.m." : ""}` : "-"}
  </ListGroup.Item>
);

export const Tekort = ({
  tekort,
  uitgangspunten,
  beidenTonen,
  aanvrager,
  partner,
  header
}: TekortProps): ReactElement => {
  const hypotheekBedragenTonen = uitgangspunten.controleHypotheeknorm?.controleerMaximaleHypotheek;
  const inkomensBedragenTonen =
    uitgangspunten.controleHypotheeknorm?.controleMaandlast ||
    uitgangspunten.controleGewenstInkomen?.controleGewenstInkomen !== ControleGewenstInkomenOptions.Geen;

  const aanvragerTonen = uitgangspunten.scenarioVanKlantIds.includes(aanvrager.klantId);
  const partnerTonen = partner && uitgangspunten.scenarioVanKlantIds.includes(partner.klantId);
  const beideTonen = beidenTonen && uitgangspunten.scenarioVanKlantIds.length === 2;

  const tekortType = hypotheekBedragenTonen ? "Tekort op hypotheek" : "Tekort op inkomen";
  const tooltip = `${header} - ${tekortType}`;

  return (
    <div className="tekort">
      <ListGroup variant="flush" className="tekort__container" data-testid="tekort-testid" title={tooltip}>
        {hypotheekBedragenTonen && (
          <>
            {aanvragerTonen && <TekortItem label="Aanvrager" value={tekort.tekortHypotheek1Bedrag} />}
            {partnerTonen && <TekortItem label="Partner" value={tekort.tekortHypotheek2Bedrag} />}
            {beideTonen && <TekortItem label="Beiden" value={tekort.tekortHypotheekBeideBedrag} />}
          </>
        )}
        {inkomensBedragenTonen && (
          <>
            {aanvragerTonen && <TekortItem label="Aanvrager" value={tekort.tekortInkomen1Bedrag} perMonth />}
            {partnerTonen && <TekortItem label="Partner" value={tekort.tekortInkomen2Bedrag} perMonth />}
            {beideTonen && <TekortItem label="Beiden" value={tekort.tekortInkomenBeideBedrag} perMonth />}
          </>
        )}
      </ListGroup>
    </div>
  );
};
