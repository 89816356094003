/* istanbul ignore file */
import {
  CheckBox,
  LabeledCurrencyInput,
  LabeledPercentageInput,
  LabeledRadioInput,
  LabeledSelectInput,
  RadioInputGroup,
  Label,
  TooltipWrap
} from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { ControleGewenstInkomenOptions, ControleGewenstNbiOptions } from "../../../.generated/forms/formstypes";
import { Direction } from "../../../shared/types";
import { DashboardState, NamePrefix } from "../../infra/dashboard-types";
import {
  controleGewenstInkomenOptions,
  controleMaandlastOptions,
  controleNBIoptions
} from "./infra/controle-norm-options";
import "./controle-norm.scss";

type ControleNormProps = {
  namePrefix: NamePrefix;
};

export const ControleNorm = ({ namePrefix }: ControleNormProps): ReactElement => {
  const { values } = useFormikContext<DashboardState>();

  return (
    <>
      <CheckBox name={`${namePrefix}.controleNorm.controleMaandlast`} caption="Controle maandlast" />

      {values[namePrefix].controleNorm.controleMaandlast && (
        <div className="ml-3">
          <RadioInputGroup
            options={controleMaandlastOptions}
            name={`${namePrefix}.controleNorm.controleMaandLastKeuze`}
            layout={Direction.Vertical}
          />
        </div>
      )}
      <CheckBox name={`${namePrefix}.controleNorm.maximaleHypotheeksom`} caption="Maximale hypotheeksom" />
      <h3 className="pl-3 pt-3">Controle op gewenst inkomen</h3>
      <LabeledRadioInput
        caption="Controle op gewenst inkomen"
        name={`${namePrefix}.controleNorm.controleGewenstInkomen`}
        options={controleGewenstInkomenOptions}
        labelColSize={6}
      />

      {values[namePrefix].controleNorm.controleGewenstInkomen === ControleGewenstInkomenOptions.Nbi && (
        <LabeledSelectInput
          caption="Gewenst netto besteedbaar inkomen"
          name={`${namePrefix}.controleNorm.gewenstNettoBesteedbaarInkomen`}
          options={controleNBIoptions}
          className="mt-2"
          labelColSize={6}
        />
      )}

      {values[namePrefix].controleNorm.controleGewenstInkomen === ControleGewenstInkomenOptions.Nbi &&
        values[namePrefix].controleNorm.gewenstNettoBesteedbaarInkomen &&
        values[namePrefix].controleNorm.gewenstNettoBesteedbaarInkomen === ControleGewenstNbiOptions.MargeHuidigNbi && (
          <>
            <LabeledPercentageInput
              caption="Acceptabele marge op huidig NBI"
              name={`${namePrefix}.controleNorm.margeBovengrensPercentage`}
              decimalen={0}
              appendChildren={
                <div className="d-flex align-items-center ml-2">
                  <span>Bovengrens</span>
                </div>
              }
              labelColSize={6}
            />
            <LabeledPercentageInput
              caption=""
              name={`${namePrefix}.controleNorm.margeOndergrensPercentage`}
              decimalen={0}
              appendChildren={
                <div className="d-flex align-items-center ml-2">
                  <span>Ondergrens</span>
                </div>
              }
              labelColSize={6}
            />
          </>
        )}

      {values[namePrefix].controleNorm.controleGewenstInkomen === ControleGewenstInkomenOptions.BrutoInkomenswens && (
        <LabeledPercentageInput
          caption="Bruto inkomenswens"
          name={`${namePrefix}.controleNorm.brutoInkomenswens`}
          decimalen={0}
          labelColSize={6}
        />
      )}

      {values[namePrefix].controleNorm.controleGewenstInkomen === ControleGewenstInkomenOptions.Nbi &&
        values[namePrefix].controleNorm.gewenstNettoBesteedbaarInkomen &&
        values[namePrefix].controleNorm.gewenstNettoBesteedbaarInkomen ===
          ControleGewenstNbiOptions.PercentageHuidigNbi && (
          <LabeledPercentageInput
            caption="Gewenst % van huidig NBI"
            name={`${namePrefix}.controleNorm.gewenstPercentageHuidigNbi`}
            decimalen={0}
            labelColSize={6}
          />
        )}

      {values[namePrefix].controleNorm.controleGewenstInkomen === ControleGewenstInkomenOptions.Nbi &&
        values[namePrefix].controleNorm.gewenstNettoBesteedbaarInkomen &&
        values[namePrefix].controleNorm.gewenstNettoBesteedbaarInkomen === ControleGewenstNbiOptions.VastBedrag && (
          <LabeledCurrencyInput
            caption="Gewenst vast bedrag NBI"
            name={`${namePrefix}.controleNorm.gewenstVastBedrag`}
            labelColSize={6}
          />
        )}

      {values[namePrefix].controleNorm.controleGewenstInkomen === ControleGewenstInkomenOptions.Nbi && (
        <div className="row">
          <>
            <div className="select-input col-6">
              <Label
                caption="Selecteer de gewenste tabellen voor het toelichten van het netto besteedbaar inkomen."
                labelColSize={5}
                appendChildren={
                  <TooltipWrap
                    name="gewenste-tabellen"
                    warningText="De tabel ‘Gewijzigde jaren’ toont het netto besteedbaar inkomen per (gewijzigd) jaar. Het onderdeel ‘Benodigd kapitaal’ toont het direct benodigde kapitaal om het tekort op het gewenste netto besteedbaar inkomen af te dekken."
                    placement="bottom"
                    iconType="questionmark"
                    tooltipClasses="question-tip"
                  >
                    <Button className="question-tip" id="gewenste-tabellen" />
                  </TooltipWrap>
                }
              />
            </div>
            <div className="col-6">
              <CheckBox caption="Gewijzigde jaren" name={`${namePrefix}.controleNorm.gewijzigdeJaren`} />
              <CheckBox caption="Benodigd kapitaal" name={`${namePrefix}.controleNorm.benodigdKapitaal`} />
            </div>
          </>
        </div>
      )}
    </>
  );
};
