import React from 'react';
import { Table } from 'react-bootstrap';
import { bedragFormatNull } from '../infra/scenario-utils';

export const ControleWerkelijkeLastenTable: React.FC<{
    financieleGegevens?: {
      toegestaneLast: number | null;
      werkelijkeBrutoLast: number | null;
      tekortWerkelijkeBrutolast: number | null;
    };
  }> = ({ financieleGegevens } : any) => (
    <Table striped bordered hover responsive className="scenario__table mb-4">
      <colgroup>
        <col style={{ width: "70%" }} />
        <col style={{ width: "30%", textAlign: "right" }} />
      </colgroup>
      <tbody>
        <tr>
          <td>Toegestane last o.b.v. inkomen**</td>
          <td>{bedragFormatNull(financieleGegevens?.toegestaneLast)}</td>
        </tr>
        <tr>
          <td>Werkelijke bruto last van hypotheek en verpande producten</td>
          <td>{bedragFormatNull(financieleGegevens?.werkelijkeBrutoLast)}</td>
        </tr>
        <tr>
          <td>
            <strong>Tekort o.b.v. werkelijke bruto last</strong>
          </td>
          <td>{bedragFormatNull(Math.min(financieleGegevens?.tekortWerkelijkeBrutolast || 0, 0))}</td>
        </tr>
      </tbody>
    </Table>
  );