import React from 'react';
import { Table } from 'react-bootstrap';
import { bedragFormatNull } from '../infra/scenario-utils';

export const BenodigdInkomenTable: React.FC<{
  financieleGegevens?: {
    benodigdInkomenHypotheek: number | null;
    tekortOpInkomen: number | null;
  };
}> = ({ financieleGegevens } : any) => (
  <Table striped bordered hover responsive className="scenario__table mb-4">
    <colgroup>
      <col style={{ width: "70%" }} />
      <col style={{ width: "30%", textAlign: "right" }} />
    </colgroup>
    <tbody>
      <tr>
        <td>Minimaal benodigd inkomen voor uw hypotheek*</td>
        <td>{bedragFormatNull(financieleGegevens?.benodigdInkomenHypotheek)}</td>
      </tr>
      <tr className={"table-danger font-weight-bold"}>
        <td>Maandelijks tekort aan inkomsten voor uw hypotheek</td>
        <td>{bedragFormatNull(financieleGegevens?.tekortOpInkomen)}</td>
      </tr>
    </tbody>
  </Table>
);
