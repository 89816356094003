/* istanbul ignore file */
/* eslint-disable */
import React from "react";
import { Table } from "react-bootstrap";
import { LabeledRadioInput } from "adviesbox-shared";
import "./scenario-table.scss";
import classNames from "classnames";
import { NbiResult } from "../../../.generated/rapportage/rapportagetypes";
import { TableNettoBesteedbaarInkomen } from "./nbi";
import { ScenarioBaseProps } from "./scenario-drawer";
import { Formik } from "formik";
import { Direction } from "../../../shared/types";
import {
  monthDivision,
  bedragFormatNull,
  getConclusieArcering,
  getMaandlastKeuzeTekst,
  getTrArcering
} from "./infra/scenario-utils";
import { ControleMaandlastOptions } from "../../../.generated/forms/formstypes";
import { CardLoadingSpinner } from "./card-loading-spinner";
import { getNaam } from "../../../shared/utils/helpers";

export type ArbeidsongeschiktheidProps = {
  nbiWeergeven: boolean;
  titel: string;
  conclusieEerste: string;
  conclusieTweede: string;
  conclusieDerde: string;
  nbi: NbiResult[];
  inkomensData?: {
    persoon: string;
    inkomenAanvragerEerstePeriode: number | null;
    inkomenAanvragerTweedePeriode: number | null;
    inkomenAanvragerDerdePeriode: number | null;
    inkomenPartnerEerstePeriode: number | null;
    inkomenPartnerTweedePeriode: number | null;
    inkomenPartnerDerdePeriode: number | null;
    totaalInkomenEerstePeriode: number | null;
    totaalInkomenTweedePeriode: number | null;
    totaalInkomenDerdePeriode: number | null;
    inkomensverliesEerste: number | null;
    inkomensverliesTweede: number | null;
    inkomensverliesDerde: number | null;
    wgaUitkeringAanvragerEerstePeriode: number | null;
    wgaUitkeringAanvragerTweedePeriode: number | null;
    wgaUitkeringAanvragerDerdePeriode: number | null;
    ivaUitkeringAanvragerEerstePeriode: number | null;
    ivaUitkeringAanvragerTweedePeriode: number | null;
    ivaUitkeringAanvragerDerdePeriode: number | null;
    percentageAoEerste: number | null;
    percentageAoTweede: number | null;
    percentageAoDerde: number | null;
    woonlastenEerstePeriode: number | null;
    woonlastenTweedePeriode: number | null;
    woonlastenDerdePeriode: number | null;
    rvcEerste: number | null;
    rvcTweede: number | null;
    rvcDerde: number | null;
  };
  jaarlijkseAnalyse?: {
    gewenstInkomen: number | null;
    maandelijksTekortEerste: number | null;
    maandelijksTekortTweede: number | null;
    maandelijksTekortDerde: number | null;
  };
  financieleGegevens?: {
    tekortWerkelijk: number | null;
    toegestaneLast: number | null;
    tekortWerkelijkeBrutolast: number | null;
    werkelijkeBrutoLast: number | null;
    maximaleHypotheekEerste: number | null;
    maximaleHypotheekTweede: number | null;
    maximaleHypotheekDerde: number | null;
    hypotheekSaldo: number | null;
    overschrijding: number | null;
    benodigdInkomenHypotheek: number | null;
    tekortOpInkomen: number | null;
    tekortOpInkomenTweede: number | null;
    tekortOpInkomenDerde: number | null;
    maandelijksTekortHypotheekEerste: number | null;
    maandelijksTekortHypotheekTweede: number | null;
    maandelijksTekortHypotheekDerde: number | null;
  };
  voetnoten: string[];
  benodigdKapitaal: number | null;
  benodigdKapitaalOmschrijving: string;
};

export const InkomensanalyseTabelArbeidsongeschiktheid: React.FC<ArbeidsongeschiktheidProps & ScenarioBaseProps> = ({
  titel,
  conclusieEerste: conclusie,
  inkomensData,
  jaarlijkseAnalyse,
  financieleGegevens,
  voetnoten,
  nbi,
  benodigdKapitaal,
  benodigdKapitaalOmschrijving,
  nbiWeergeven,
  weergevenOptions,
  selectedKlantId,
  onKlantIdChange,
  naamAanvrager,
  naamPartner,
  benodigdKapitaalWeergeven,
  controleWerkelijkeLastenWeergeven,
  brutoInkomensWensenWeergeven,
  brutoInkomensWensPercentage,
  controleMaandlast,
  controleMaandlastKeuze,
  maximaleHypotheekWeergeven,
  loading
}) => {
  const columnStyleLabel = {
    width: "40%"
  };
  const columnStyle = {
    width: "20%",
    textAlign: "right" as const
  };

  return (
    <div className={`container-fluid mt-2 px-2 ${classNames("", { spinner: loading })}`}>
      <h2 className="mb-3">{titel}</h2>
      {loading && <CardLoadingSpinner />}
      {weergevenOptions.length > 0 && (
        <Formik initialValues={{ scenarioKlantId: selectedKlantId }} enableReinitialize onSubmit={() => {}}>
          {({ setFieldValue }) => (
            <div className={"col-md-6 pb-3"}>
              <LabeledRadioInput
                name={"scenarioKlantId"}
                caption={"Weergeven scenario van"}
                options={weergevenOptions}
                onChange={event => {
                  onKlantIdChange(event.target.value);
                  setFieldValue("scenarioKlantId", event.target.value);
                }}
                layout={Direction.Horizontal}
              />
            </div>
          )}
        </Formik>
      )}
      <Table striped bordered hover responsive className="scenario__table mb-4">
        <colgroup>
          <col style={columnStyleLabel} />
          <col style={columnStyle} />
          <col style={columnStyle} />
          <col style={columnStyle} />
        </colgroup>
        <thead>
          <tr>
            <th>Uw inkomen over vier jaar bij arbeidsongeschiktheid van {getNaam(naamAanvrager, "Aanvrager 1")}</th>
            <th className={"text-center"}>{inkomensData?.percentageAoEerste}% AO</th>
            <th className={"text-center"}>{inkomensData?.percentageAoTweede}% AO</th>
            <th className={"text-center"}>
              {(inkomensData?.percentageAoDerde || 0) > 0 ? inkomensData?.percentageAoDerde + "% AO" : ""}{" "}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={"font-weight-bold"}>
            <td></td>
            <td className={"text-center"}>{inkomensData?.rvcEerste}% WERKEN</td>
            <td className={"text-center"}>{inkomensData?.rvcTweede}% WERKEN</td>
            <td className={"text-center"}>{inkomensData?.rvcDerde}% WERKEN</td>
          </tr>
          <tr>
            <td>Restinkomen {getNaam(naamAanvrager, "Aanvrager 1")}</td>
            <td>{bedragFormatNull(inkomensData?.inkomenAanvragerEerstePeriode, monthDivision)}</td>
            <td>{bedragFormatNull(inkomensData?.inkomenAanvragerTweedePeriode, monthDivision)}</td>
            <td>{bedragFormatNull(inkomensData?.inkomenAanvragerDerdePeriode, monthDivision)}</td>
          </tr>
          <tr>
            <td>Woonlastenverzekering</td>
            <td>{bedragFormatNull(inkomensData?.woonlastenEerstePeriode, monthDivision)}</td>
            <td>{bedragFormatNull(inkomensData?.woonlastenTweedePeriode, monthDivision)}</td>
            <td>{bedragFormatNull(inkomensData?.woonlastenDerdePeriode, monthDivision)}</td>
          </tr>
          <tr>
            <td>Uitkering IVA {getNaam(naamAanvrager, "Aanvrager 1")}</td>
            <td>{bedragFormatNull(inkomensData?.ivaUitkeringAanvragerEerstePeriode, monthDivision)}</td>
            <td>{bedragFormatNull(inkomensData?.ivaUitkeringAanvragerTweedePeriode, monthDivision)}</td>
            <td>{bedragFormatNull(inkomensData?.ivaUitkeringAanvragerDerdePeriode, monthDivision)}</td>
          </tr>
          <tr>
            <td>Uitkering WGA {getNaam(naamAanvrager, "Aanvrager 1")}</td>
            <td>{bedragFormatNull(inkomensData?.wgaUitkeringAanvragerEerstePeriode, monthDivision)}</td>
            <td>{bedragFormatNull(inkomensData?.wgaUitkeringAanvragerTweedePeriode, monthDivision)}</td>
            <td>{bedragFormatNull(inkomensData?.wgaUitkeringAanvragerDerdePeriode, monthDivision)}</td>
          </tr>
          {naamPartner && (
            <tr>
              <td>Inkomen {getNaam(naamPartner, "Aanvrager 2")}</td>
              <td>{bedragFormatNull(inkomensData?.inkomenPartnerEerstePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.inkomenPartnerTweedePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.inkomenPartnerDerdePeriode, monthDivision)}</td>
            </tr>
          )}
          <tr className="font-weight-bold">
            <td>Inkomen totaal</td>
            <td>{bedragFormatNull(inkomensData?.totaalInkomenEerstePeriode, monthDivision)}</td>
            <td>{bedragFormatNull(inkomensData?.totaalInkomenTweedePeriode, monthDivision)}</td>
            <td>{bedragFormatNull(inkomensData?.totaalInkomenDerdePeriode, monthDivision)}</td>
          </tr>
          <tr className={"table-warning"}>
            <td>Inkomensverlies</td>
            <td>{bedragFormatNull(inkomensData?.inkomensverliesEerste)}</td>
            <td>{bedragFormatNull(inkomensData?.inkomensverliesTweede)}</td>
            <td>{bedragFormatNull(inkomensData?.inkomensverliesDerde)}</td>
          </tr>
        </tbody>
      </Table>
      {brutoInkomensWensenWeergeven && (
        <>
          <Table striped bordered hover responsive className="scenario__table mb-4">
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
              <col style={columnStyle} />
              <col style={columnStyle} />
            </colgroup>
            <tbody>
              <tr>
                <td>Gewenst inkomen van minimaal {brutoInkomensWensPercentage}% van huidig bruto inkomen</td>
                <td>{bedragFormatNull(jaarlijkseAnalyse?.gewenstInkomen, monthDivision)}</td>
                <td>{bedragFormatNull(jaarlijkseAnalyse?.gewenstInkomen, monthDivision)}</td>
                <td>{bedragFormatNull(jaarlijkseAnalyse?.gewenstInkomen, monthDivision)}</td>
              </tr>
              <tr className={"table-danger font-weight-bold"}>
                <td>Maandelijks tekort aan gewenste inkomsten</td>
                <td>{bedragFormatNull(Math.abs(jaarlijkseAnalyse?.maandelijksTekortEerste || 0), monthDivision)}</td>
                <td>{bedragFormatNull(Math.abs(jaarlijkseAnalyse?.maandelijksTekortTweede || 0), monthDivision)}</td>
                <td>{bedragFormatNull(Math.abs(jaarlijkseAnalyse?.maandelijksTekortDerde || 0), monthDivision)}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
      <Table striped bordered hover responsive className="scenario__table mb-4">
        <colgroup>
          <col style={columnStyleLabel} />
          <col style={columnStyle} />
          <col style={columnStyle} />
          <col style={columnStyle} />
        </colgroup>
        <tbody>
          {controleWerkelijkeLastenWeergeven && (
            <>
              <tr>
                <td>Toegestane last o.b.v. inkomen**</td>
                <td>{bedragFormatNull(financieleGegevens?.toegestaneLast)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Werkelijke bruto last van hypotheek en verpande producten</td>
                <td>{bedragFormatNull(financieleGegevens?.werkelijkeBrutoLast)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr className={getTrArcering(true)}>
                <td>
                  <strong>Tekort o.b.v. werkelijke bruto last</strong>
                </td>
                <td>{bedragFormatNull(Math.abs(financieleGegevens?.tekortWerkelijkeBrutolast || 0))}</td>
                <td></td>
                <td></td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
      {controleMaandlastKeuze === ControleMaandlastOptions.BenodigdInkomen && (
        <Table striped bordered hover responsive className="scenario__table  mb-4">
          <colgroup>
            <col style={columnStyleLabel} />
            <col style={columnStyle} />
            <col style={columnStyle} />
            <col style={columnStyle} />
          </colgroup>
          <tbody>
            <tr>
              <td>Minimaal benodigd inkomen voor uw hypotheek</td>
              <td>{bedragFormatNull(financieleGegevens?.benodigdInkomenHypotheek)}</td>
              <td>{bedragFormatNull(financieleGegevens?.benodigdInkomenHypotheek)}</td>
              <td>{bedragFormatNull(financieleGegevens?.benodigdInkomenHypotheek)}</td>
            </tr>
            <tr className={"table-danger font-weight-bold"}>
              <td>Maandelijks tekort aan inkomsten voor uw hypotheek</td>
              <td>{bedragFormatNull(Math.abs(financieleGegevens?.tekortOpInkomen || 0))}</td>
              <td>{bedragFormatNull(Math.abs(financieleGegevens?.tekortOpInkomenTweede || 0))}</td>
              <td>{bedragFormatNull(Math.abs(financieleGegevens?.tekortOpInkomenDerde || 0))}</td>
            </tr>
            <tr className={"table-danger font-weight-bold"}>
              <td>Netto maandelijks tekort voor uw hypotheek</td>
              <td>{bedragFormatNull(Math.abs(financieleGegevens?.maandelijksTekortHypotheekEerste || 0))}</td>
              <td>{bedragFormatNull(Math.abs(financieleGegevens?.maandelijksTekortHypotheekTweede || 0))}</td>
              <td>{bedragFormatNull(Math.abs(financieleGegevens?.maandelijksTekortHypotheekDerde || 0))}</td>
            </tr>
          </tbody>
        </Table>
      )}

      <Table striped bordered hover responsive className="scenario__table mb-4">
        <colgroup>
          <col style={columnStyleLabel} />
          <col style={columnStyle} />
          <col style={columnStyle} />
          <col style={columnStyle} />
        </colgroup>
        <tbody>
          {maximaleHypotheekWeergeven && (
            <>
              <tr>
                <td>Uw maximale hypotheek**</td>
                <td>{bedragFormatNull(financieleGegevens?.maximaleHypotheekEerste)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Uw hypotheeksaldo</td>
                <td>{bedragFormatNull(financieleGegevens?.hypotheekSaldo)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr className={"table-danger font-weight-bold"}>
                <td>Overschrijding van uw maximale hypotheek</td>
                <td>{bedragFormatNull(financieleGegevens?.overschrijding)}</td>
                <td></td>
                <td></td>
              </tr>
            </>
          )}
        </tbody>
      </Table>

      {controleMaandlast && (
        <Table striped bordered hover responsive className={"mb-4"}>
          <colgroup>
            <col style={columnStyleLabel} />
            <col style={columnStyle} />
            <col style={columnStyle} />
            <col style={columnStyle} />
          </colgroup>
          <tbody>
            <tr>
              <td>
                De maandlast die overblijft voor deze situatie t.o.v. de{" "}
                {getMaandlastKeuzeTekst(controleMaandlastKeuze)} ?
              </td>
              <td className={classNames("text-right", getConclusieArcering(conclusie))}>{conclusie}</td>
              <td className={classNames("text-right", getConclusieArcering(conclusie))}>{conclusie}</td>
              <td className={classNames("text-right", getConclusieArcering(conclusie))}>{conclusie}</td>
            </tr>
          </tbody>
        </Table>
      )}

      <p className="mt-3 text-right px-2">
        {voetnoten?.map((voetnoot, index) => (
          <React.Fragment key={index}>
            <span>{voetnoot}</span>
            <br />
          </React.Fragment>
        ))}
      </p>

      {nbiWeergeven && <TableNettoBesteedbaarInkomen nbi={nbi} />}

      {benodigdKapitaalWeergeven && (
        <Table striped bordered hover responsive className={"scenario__table mb-4"}>
          <colgroup>
            <col style={{ width: "40%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>Uw risico o.b.v. uw gewenste inkomen</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{benodigdKapitaalOmschrijving}</td>
              <td>{bedragFormatNull(benodigdKapitaal)}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
};
