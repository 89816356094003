import React from 'react';
import { ControleMaandlastOptions } from '../../../../.generated/forms/formstypes';
import { Table } from 'react-bootstrap';
import { getMaandlastKeuzeTekst, getConclusieArcering } from '../infra/scenario-utils';
import classNames from 'classnames';

export const ControleMaandlastTable: React.FC<{
    conclusie: string;
    controleMaandlastKeuze: ControleMaandlastOptions | null;
  }> = ({ conclusie, controleMaandlastKeuze } : any) => (
    <Table striped bordered hover responsive className="scenario__table mb-4">
      <colgroup>
        <col style={{ width: "70%" }} />
        <col style={{ width: "30%", textAlign: "right" }} />
      </colgroup>
      <tbody>
        <tr>
          <td>
            De maandlast die overblijft voor deze situatie t.o.v. de{" "}
            {getMaandlastKeuzeTekst(controleMaandlastKeuze)} ?
          </td>
          <td className={classNames("text-right", getConclusieArcering(conclusie))}>{conclusie}</td>
        </tr>
      </tbody>
    </Table>
  );