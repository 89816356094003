import {
  AuthContext,
  FileUpload,
  hasJSONResponse,
  hasNoRoleIsw,
  Icon,
  ModalButton,
  SettingsContext,
  useRequestInit,
  FormFirstFocus,
  insightsReactPlugin
} from "adviesbox-shared";
import { RouteParams } from "adviesbox-shared/utils/types";
import classnames from "classnames";
import { Form } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { useParams } from "react-router";
import { AboxImportResult, HbxImportResult } from "../.generated/klantdossiers-conversie/klantdossiers-conversietypes";
import { Debug } from "../shared/components/formik/Debug";
import TopNavbar from "../shared/components/topnav-dossier/TopNavbar";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { zoekschermSchema, ZoekschermState } from "./infra/zoekscherm-schema";
import LaatsteDossiers from "./laatste-dossiers/laatste-dossiers";
import NieuweKlantModal from "./nieuwe-klant-modal/nieuwe-klant-modal";
import Zoeken from "./zoeken/zoeken";
import classes from "./zoekscherm.module.scss";
import { getZoekenTextResources } from "./infra/zoeken-resources";
import ExternekoppelingenDossiers from "./externekoppelingen-dossiers/externekoppelingen-dossiers";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const Zoekscherm = (): ReactElement => {
  const [showDossiers, setShowDossiers] = useState(false);
  const [showExterneDossiers, setShowExterneDossiers] = useState(false);

  /*istanbul ignore next*/
  const toggleShowDossiers = (): void => {
    setShowDossiers(!showDossiers);
  };

  /*istanbul ignore next*/
  const toggleShowExterneDossiers = (): void => {
    setShowExterneDossiers(!showExterneDossiers);
  };
  const { user } = useContext(AuthContext);
  const settings = useContext(SettingsContext);

  const isIswMedewerker = !hasNoRoleIsw(user);
  const requestContext = useRequestInit();
  const { vestiging: vestigingId } = useParams<RouteParams>();

  /*istanbul ignore next*/
  const handleResult = (result: AboxImportResult | HbxImportResult): void => {
    if (!result.isValid) {
      let validationResults = "";
      if (result.validationResults) {
        result.validationResults.forEach(element => {
          validationResults = `${validationResults} ${element.code}(${element.target}):${element.message}`;
        });
      }
      alert(`Er ging iets fout: ${result.message} ${validationResults}`);
    }

    // redirect window to new adviesdossier
    if (!result.adviesdossierIds || !result.adviesdossierIds.length)
      alert(getZoekenTextResources("GeenAdviesdossierNaImport"));

    const newLocation =
      window.location.origin +
      `/vestiging/${vestigingId}/adviesdossier/${(result?.adviesdossierIds || [])[0]}/personalia`;

    window.location.assign(newLocation);
  };

  return (
    <div className={classes.loader_correction}>
      <TopNavbar home={true} />
      <FormFirstFocus>
        <Form>
          <div className={`${classes.zoekscherm_header} py-4`}>
            <div className="mb-2 d-flex">
              <h1 className="mx-auto">Adviesbox Advies</h1>
            </div>
            <Zoeken />
            <div
              id="nieuwe_klant_button"
              className={classnames("d-flex justify-content-center w-100 fileupload_no_styling", classes.modalbtn)}
            >
              <ModalButton
                validate={false}
                parent="nieuweKlant"
                className="btn-primary"
                content={<div>+ Nieuwe klant</div>}
              >
                <NieuweKlantModal />
              </ModalButton>

              {/* upload functionaliteit abox */}
              {isIswMedewerker && (
                <>
                  &nbsp;
                  <FileUpload
                    name="aboxUpload"
                    updateRef={
                      /*istanbul ignore next*/
                      async (files, name) => {
                        if (!files.length) {
                          alert(getZoekenTextResources("GeenFileGeselecteerdVoorImport"));
                          return;
                        }

                        const formData = new FormData();
                        formData.append("uploadedAbox", files[0], name);

                        const response = await fetch(`${settings.klantdossiersConversieOrigin}/Abox/upload`, {
                          ...requestContext.requestInit,
                          headers: { ...requestContext.requestInit.headers },
                          method: "POST",
                          body: formData
                        });

                        if (response.ok && hasJSONResponse(response)) {
                          const result: AboxImportResult = await response.json();
                          handleResult(result);
                        } else {
                          alert(getZoekenTextResources("FoutBijImport"));
                        }
                      }
                    }
                    multipleFiles={false}
                    variant={"AlleBestanden"}
                    buttonText={"+ Abox importeren [ISW functionaliteit]"}
                    //filesref={filesRef}
                    hideFormats
                    hideIcon
                    allowExtensions={[".abox"]}
                  ></FileUpload>
                </>
              )}

              <>
                &nbsp;
                <FileUpload
                  name="hbxUpload"
                  updateRef={
                    /*istanbul ignore next*/
                    async (files, name) => {
                      if (!files.length) {
                        alert(getZoekenTextResources("GeenFileGeselecteerdVoorImport"));
                        return;
                      }

                      const formData = new FormData();
                      formData.append("uploadedhbx", files[0], name);

                      const response = await fetch(
                        `${settings.klantdossiersConversieOrigin}/HBX/upload?adviseurOverschrijvenMetIngelogdeGebruiker=true`,
                        {
                          ...requestContext.requestInit,
                          headers: { ...requestContext.requestInit.headers },
                          method: "POST",
                          body: formData
                        }
                      );

                      if (response.ok && hasJSONResponse(response)) {
                        const result: HbxImportResult = await response.json();
                        handleResult(result);
                      } else {
                        alert(getZoekenTextResources("FoutBijImport"));
                      }
                    }
                  }
                  multipleFiles={false}
                  variant={"AlleBestanden"}
                  buttonText={"+ Advies (hbx) inlezen"}
                  //filesref={filesRef}
                  hideFormats
                  hideIcon
                  allowExtensions={[".hbx"]}
                ></FileUpload>
              </>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className={`card card-adviesbox ${classes.zoeken_cards}`}>
                  <button
                    type="button"
                    onClick={toggleShowDossiers}
                    className={`${classes.clickable} d-flex flex-row flex-wrap`}
                    id="toggle-show-dossiers"
                  >
                    <h2 className={"m-0 align-self-center"}>Mijn laatst geopende dossiers</h2>
                    <div className={`${classes.icon} ml-auto`}>
                      <>
                        {!showDossiers && <Icon name="chevron" alt="pijl-omlaag" />}
                        {showDossiers && (
                          <div className={classes.chevron_up}>
                            <Icon name="chevron" alt="pijl-omhoog" />
                          </div>
                        )}
                      </>
                    </div>
                    <div className={classes.dossiers} onClick={/* istanbul ignore next */(e) => e.stopPropagation()}>
                      <LaatsteDossiers showDossiers={showDossiers} />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* TODO: Voor nu alleen in development en test zichtbaar. Als import volledig is gebouwd, de volgende regel verwijderen zodat e.e.a. ook in ACC en PROD beschikbaar wordt. */}
          {(settings.externeKoppelingenAvailable === true) && (
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className={`card card-adviesbox ${classes.zoeken_cards}`}>
                    <div
                      onClick={toggleShowExterneDossiers}
                      className={`${classes.clickable} d-flex flex-row flex-wrap`}
                      id="toggle-show-externe-dossiers"
                      data-testid="toggle-show-externe-dossiers"
                    >
                      <h2 className={"m-0 align-self-center"}>Externe dossiers wachtend op goedkeuring</h2>
                      <div className={`${classes.icon} ml-auto`}>
                        <>
                          {!showExterneDossiers && <Icon name="chevron" alt="pijl-omlaag" />}
                          {showExterneDossiers && (
                            <div className={classes.chevron_up}>
                              <Icon name="chevron" alt="pijl-omhoog" />
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                    {showExterneDossiers && <ExternekoppelingenDossiers showExterneDossiers={showExterneDossiers} />}
                  </div>
                </div>
              </div>
            </div>
          )}

          <Debug />
        </Form>
      </FormFirstFocus>
    </div>
  );
};

Zoekscherm.displayName = "Zoekscherm";

export default withAdviesboxFormik<ZoekschermState, ZoekschermState>({
  mapPropsToValues: (val): ZoekschermState => val,
  validationSchema: zoekschermSchema
})(withAITracking(insightsReactPlugin, Zoekscherm));
