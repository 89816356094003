import React from 'react';
import { Table } from 'react-bootstrap';
import { bedragFormatNull } from '../infra/scenario-utils';

export const MaximaleHypotheekTable: React.FC<{
    financieleGegevens?: {
      maximaleHypotheek: number | null;
      hypotheekSaldo: number | null;
      overschrijding: number | null;
    };
  }> = ({ financieleGegevens } : any) => (
    <Table striped bordered hover responsive className="scenario__table mb-4">
      <colgroup>
        <col style={{ width: "70%" }} />
        <col style={{ width: "30%", textAlign: "right" }} />
      </colgroup>
      <tbody>
        <tr>
          <td>Uw maximale hypotheek**</td>
          <td>{bedragFormatNull(financieleGegevens?.maximaleHypotheek)}</td>
        </tr>
        <tr>
          <td>Uw hypotheeksaldo</td>
          <td>{bedragFormatNull(financieleGegevens?.hypotheekSaldo)}</td>
        </tr>
        <tr className={"table-danger font-weight-bold"}>
          <td>Overschrijding van uw maximale hypotheek</td>
          <td>{bedragFormatNull(financieleGegevens?.overschrijding, (x) => x, 0, true)}</td>
        </tr>
      </tbody>
    </Table>
  );
  