import React, { useContext, ReactElement, useCallback } from "react";
import { Button } from "react-bootstrap";
import { DashboardDataContext, NewDashboardType } from "./new-dashboard-context";
import { SnelInzichtResultaat } from "./snel-inzicht/snel-inzicht";
import { HypotheekAcceptatieResultaat } from "./hypotheek-acceptatie/hypotheek-acceptatie";
import { ArbeidsongeschiktheidResultaat } from "./arbeidsongeschiktheid/arbeidsongeschiktheid";
import { OverlijdenResultaat } from "./overlijden/overlijden";
import { WerkloosheidResultaat } from "./werkloosheid/werkloosheid";
import { PensioenResultaat } from "./pensioen/pensioen";
import { useRefreshTrigger } from "./infra/use-refresh-trigger";
import "./new-dashboard.scss";
import classNames from "classnames";
import { useRequestInit, FeatureComponent } from "adviesbox-shared";
import { User } from "oidc-client";
import { AppDataContext } from "../../navigation/appdata-context";
import DashboardProvider from "../infra/dashboard-provider";
import DashboardContext from "../infra/dashboard-context";

const DashboardContent = ({
  situatie,
  onClickOnderwerp
}: {
  situatie: string;
  onClickOnderwerp?: (type: NewDashboardType) => void;
}): ReactElement => {
  const { triggerRefresh } = useRefreshTrigger();
  const { open, type, setOpen, setType } = useContext(DashboardDataContext);
  const { params, settings, user } = useRequestInit();
  const { isPageDirty } = useContext(AppDataContext);
  const { dashboardValues } = useContext(DashboardContext);

  const cleanCache = async (user: User): Promise<void> => {
    try {
      if ((!params.adviesdossier || !params.voorstelId) && !params.vestiging) {
        throw new Error("Missing required parameters");
      }

      const response = await fetch(
        `${settings.rapportageOrigin}/Adviesdossiers/${params.adviesdossier || "null"}/Voorstellen/${params.voorstel ||
          "null"}/Motivering/Clean`,
        {
          method: "GET",
          headers: {
            vestigingId: params.vestiging || "",
            "Content-Type": "application/json",
            authorization: `${user?.token_type} ${user?.access_token}`,
            "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey
          }
        }
      );

      if (!response.ok) {
        throw new Error("Failed to clean cache");
      }

      const responseForms = await fetch(
        `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier ||
          "null"}/Voorstellen/${params.voorstel || "null"}/Dashboard/Clean`,
        {
          method: "GET",
          headers: {
            vestigingId: params.vestiging || "",
            "Content-Type": "application/json",
            authorization: `${user?.token_type} ${user?.access_token}`,
            "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey
          }
        }
      );

      if (!responseForms.ok) {
        throw new Error("Failed to clean dashboard cache");
      }

      /* eslint-disable-next-line no-console */
      console.log("Cache cleaned successfully");
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error("Error cleaning cache:", error);
      // Handle error here (e.g., show an error message to the user)
    }
  };

  const handleRefresh = async (): Promise<void> => {
    user && (await cleanCache(user));
    triggerRefresh();
  };

  const handleClickOnderwerp = useCallback(
    (newType: NewDashboardType): void => {
      if (onClickOnderwerp) {
        onClickOnderwerp(newType);
        return;
      }

      if (open && type === newType) {
        setOpen(false);
        return;
      }

      if (open && type !== NewDashboardType.Geen) {
        setType(newType);
        return;
      }

      setType(newType);
      setOpen(!open);
    },
    [onClickOnderwerp, open, type, setOpen, setType]
  );

  const isSelected = (newType: NewDashboardType): boolean => {
    if (open && newType === type) return true;
    return false;
  };

  if (!dashboardValues) {
    return <div>Loading...</div>; // Or some other loading indicator
  }

  return (
    <div className="new-dashboard">
      <div className="new-dashboard__content">
        <div className="new-dashboard__title">
          <h1>Dashboard</h1>
          <Button
            name="Berekenen"
            size="lg"
            className="new-dashboard__button"
            onClick={handleRefresh}
            disabled={!!isPageDirty}
          >
            Berekenen
          </Button>
        </div>
        <div
          className={classNames("new-dashboard__entry", {
            "new-dashboard__entry--selected": isSelected(NewDashboardType.SnelInzicht)
          })}
        >
          <SnelInzichtResultaat
            onClick={() => handleClickOnderwerp(NewDashboardType.SnelInzicht)}
            situatie={situatie}
          />
        </div>
        {situatie === "voorstel" && (
          <div
            className={classNames("new-dashboard__entry", {
              "new-dashboard__entry--selected": isSelected(NewDashboardType.HypotheekAcceptatie)
            })}
          >
            <HypotheekAcceptatieResultaat
              onClick={() => handleClickOnderwerp(NewDashboardType.HypotheekAcceptatie)}
              situatie={situatie}
            />
          </div>
        )}
        <FeatureComponent feature="FeatureNewDashboardScenarios">
          {dashboardValues.formikValues.overlijdenWeergeven && (
            <div
              className={classNames("new-dashboard__entry", {
                "new-dashboard__entry--selected": isSelected(NewDashboardType.Overlijden)
              })}
            >
              <OverlijdenResultaat
                onClick={() => handleClickOnderwerp(NewDashboardType.Overlijden)}
                situatie={situatie}
              />
            </div>
          )}
          {dashboardValues.formikValues.arbeidsongeschiktheidWeergeven && (
            <div
              className={classNames("new-dashboard__entry", {
                "new-dashboard__entry--selected": isSelected(NewDashboardType.Arbeidsongeschiktheid)
              })}
            >
              <ArbeidsongeschiktheidResultaat
                onClick={() => handleClickOnderwerp(NewDashboardType.Arbeidsongeschiktheid)}
                situatie={situatie}
              />
            </div>
          )}
          {dashboardValues.formikValues.werkloosheidWeergeven && (
            <div
              className={classNames("new-dashboard__entry", {
                "new-dashboard__entry--selected": isSelected(NewDashboardType.Werkloosheid)
              })}
            >
              <WerkloosheidResultaat
                onClick={() => handleClickOnderwerp(NewDashboardType.Werkloosheid)}
                situatie={situatie}
              />
            </div>
          )}
          {dashboardValues.formikValues.pensioenWeergeven && (
            <div
              className={classNames("new-dashboard__entry", {
                "new-dashboard__entry--selected": isSelected(NewDashboardType.Pensioen)
              })}
            >
              <PensioenResultaat onClick={() => handleClickOnderwerp(NewDashboardType.Pensioen)} situatie={situatie} />
            </div>
          )}
        </FeatureComponent>
      </div>
    </div>
  );
};

export const NewDashboardComponent = ({
  situatie,
  onClickOnderwerp
}: {
  situatie: string;
  onClickOnderwerp?: (type: NewDashboardType) => void;
}): ReactElement => {
  const { params } = useRequestInit();

  return (
    <DashboardProvider situatie={params.voorstel ? "voorstel" : "huidig"}>
      <DashboardContent situatie={situatie} onClickOnderwerp={onClickOnderwerp} />
    </DashboardProvider>
  );
};

export default NewDashboardComponent;
